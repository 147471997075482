.ReactTable {
  border: none;
}

/* workaround to prevent horizontal scroll from the vertical scroll space  */
.ReactTable .rt-tbody {
  overflow-x: hidden;
  overflow-y: scroll;
}

.ReactTable .rt-thead.-filters input {
  border: none;
  background: none;
  height: auto;
  font-size: 0.8rem;
}

/* workaround to prevent the last row from overflowing into a horizontal scroll */
.ReactTable .rt-th:last-of-type .rt-resizer {
  position: absolute;
  right: 0;
  width: 18px;
}

/* capitalise all of the table headers */
.ReactTable .rt-th {
  text-transform: capitalize;
}
