.map-marker.hint--balloon:before,
.map-marker.hint--balloon:after,
.map-marker.hint--info:before,
.map-marker.hint--info:after {
  border: none;
}

.hint--html .hint__content {
  pointer-events: inherit;
}
.map-marker.map-marker--hint-bottom-delta--0 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--0.hint--bottom:before {
  left: calc(50% - 0px);
}
.map-marker.map-marker--hint-bottom-delta--1 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--1.hint--bottom:before {
  left: calc(50% - 1px);
}
.map-marker.map-marker--hint-bottom-delta--2 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--2.hint--bottom:before {
  left: calc(50% - 2px);
}
.map-marker.map-marker--hint-bottom-delta--3 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--3.hint--bottom:before {
  left: calc(50% - 3px);
}
.map-marker.map-marker--hint-bottom-delta--4 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--4.hint--bottom:before {
  left: calc(50% - 4px);
}
.map-marker.map-marker--hint-bottom-delta--5 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--5.hint--bottom:before {
  left: calc(50% - 5px);
}
.map-marker.map-marker--hint-bottom-delta--6 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--6.hint--bottom:before {
  left: calc(50% - 6px);
}
.map-marker.map-marker--hint-bottom-delta--7 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--7.hint--bottom:before {
  left: calc(50% - 7px);
}
.map-marker.map-marker--hint-bottom-delta--8 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--8.hint--bottom:before {
  left: calc(50% - 8px);
}
.map-marker.map-marker--hint-bottom-delta--9 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--9.hint--bottom:before {
  left: calc(50% - 9px);
}
.map-marker.map-marker--hint-bottom-delta--10 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--10.hint--bottom:before {
  left: calc(50% - 10px);
}
.map-marker.map-marker--hint-bottom-delta--11 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--11.hint--bottom:before {
  left: calc(50% - 11px);
}
.map-marker.map-marker--hint-bottom-delta--12 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--12.hint--bottom:before {
  left: calc(50% - 12px);
}
.map-marker.map-marker--hint-bottom-delta--13 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--13.hint--bottom:before {
  left: calc(50% - 13px);
}
.map-marker.map-marker--hint-bottom-delta--14 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--14.hint--bottom:before {
  left: calc(50% - 14px);
}
.map-marker.map-marker--hint-bottom-delta--15 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--15.hint--bottom:before {
  left: calc(50% - 15px);
}
.map-marker.map-marker--hint-bottom-delta--16 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--16.hint--bottom:before {
  left: calc(50% - 16px);
}
.map-marker.map-marker--hint-bottom-delta--17 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--17.hint--bottom:before {
  left: calc(50% - 17px);
}
.map-marker.map-marker--hint-bottom-delta--18 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--18.hint--bottom:before {
  left: calc(50% - 18px);
}
.map-marker.map-marker--hint-bottom-delta--19 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--19.hint--bottom:before {
  left: calc(50% - 19px);
}
.map-marker.map-marker--hint-bottom-delta--20 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--20.hint--bottom:before {
  left: calc(50% - 20px);
}
.map-marker.map-marker--hint-bottom-delta--21 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--21.hint--bottom:before {
  left: calc(50% - 21px);
}
.map-marker.map-marker--hint-bottom-delta--22 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--22.hint--bottom:before {
  left: calc(50% - 22px);
}
.map-marker.map-marker--hint-bottom-delta--23 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--23.hint--bottom:before {
  left: calc(50% - 23px);
}
.map-marker.map-marker--hint-bottom-delta--24 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--24.hint--bottom:before {
  left: calc(50% - 24px);
}
.map-marker.map-marker--hint-bottom-delta--25 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--25.hint--bottom:before {
  left: calc(50% - 25px);
}
.map-marker.map-marker--hint-bottom-delta--26 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--26.hint--bottom:before {
  left: calc(50% - 26px);
}
.map-marker.map-marker--hint-bottom-delta--27 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--27.hint--bottom:before {
  left: calc(50% - 27px);
}
.map-marker.map-marker--hint-bottom-delta--28 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--28.hint--bottom:before {
  left: calc(50% - 28px);
}
.map-marker.map-marker--hint-bottom-delta--29 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--29.hint--bottom:before {
  left: calc(50% - 29px);
}
.map-marker.map-marker--hint-bottom-delta--30 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--30.hint--bottom:before {
  left: calc(50% - 30px);
}
.map-marker.map-marker--hint-bottom-delta--31 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--31.hint--bottom:before {
  left: calc(50% - 31px);
}
.map-marker.map-marker--hint-bottom-delta--32 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--32.hint--bottom:before {
  left: calc(50% - 32px);
}
.map-marker.map-marker--hint-bottom-delta--33 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--33.hint--bottom:before {
  left: calc(50% - 33px);
}
.map-marker.map-marker--hint-bottom-delta--34 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--34.hint--bottom:before {
  left: calc(50% - 34px);
}
.map-marker.map-marker--hint-bottom-delta--35 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--35.hint--bottom:before {
  left: calc(50% - 35px);
}
.map-marker.map-marker--hint-bottom-delta--36 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--36.hint--bottom:before {
  left: calc(50% - 36px);
}
.map-marker.map-marker--hint-bottom-delta--37 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--37.hint--bottom:before {
  left: calc(50% - 37px);
}
.map-marker.map-marker--hint-bottom-delta--38 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--38.hint--bottom:before {
  left: calc(50% - 38px);
}
.map-marker.map-marker--hint-bottom-delta--39 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--39.hint--bottom:before {
  left: calc(50% - 39px);
}
.map-marker.map-marker--hint-bottom-delta--40 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta--40.hint--bottom:before {
  left: calc(50% - 40px);
}
.map-marker.map-marker--hint-bottom-delta-0 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-0.hint--bottom:before {
  left: calc(50% + 0px);
}
.map-marker.map-marker--hint-bottom-delta-1 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-1.hint--bottom:before {
  left: calc(50% + 1px);
}
.map-marker.map-marker--hint-bottom-delta-2 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-2.hint--bottom:before {
  left: calc(50% + 2px);
}
.map-marker.map-marker--hint-bottom-delta-3 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-3.hint--bottom:before {
  left: calc(50% + 3px);
}
.map-marker.map-marker--hint-bottom-delta-4 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-4.hint--bottom:before {
  left: calc(50% + 4px);
}
.map-marker.map-marker--hint-bottom-delta-5 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-5.hint--bottom:before {
  left: calc(50% + 5px);
}
.map-marker.map-marker--hint-bottom-delta-6 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-6.hint--bottom:before {
  left: calc(50% + 6px);
}
.map-marker.map-marker--hint-bottom-delta-7 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-7.hint--bottom:before {
  left: calc(50% + 7px);
}
.map-marker.map-marker--hint-bottom-delta-8 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-8.hint--bottom:before {
  left: calc(50% + 8px);
}
.map-marker.map-marker--hint-bottom-delta-9 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-9.hint--bottom:before {
  left: calc(50% + 9px);
}
.map-marker.map-marker--hint-bottom-delta-10 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-10.hint--bottom:before {
  left: calc(50% + 10px);
}
.map-marker.map-marker--hint-bottom-delta-11 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-11.hint--bottom:before {
  left: calc(50% + 11px);
}
.map-marker.map-marker--hint-bottom-delta-12 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-12.hint--bottom:before {
  left: calc(50% + 12px);
}
.map-marker.map-marker--hint-bottom-delta-13 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-13.hint--bottom:before {
  left: calc(50% + 13px);
}
.map-marker.map-marker--hint-bottom-delta-14 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-14.hint--bottom:before {
  left: calc(50% + 14px);
}
.map-marker.map-marker--hint-bottom-delta-15 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-15.hint--bottom:before {
  left: calc(50% + 15px);
}
.map-marker.map-marker--hint-bottom-delta-16 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-16.hint--bottom:before {
  left: calc(50% + 16px);
}
.map-marker.map-marker--hint-bottom-delta-17 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-17.hint--bottom:before {
  left: calc(50% + 17px);
}
.map-marker.map-marker--hint-bottom-delta-18 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-18.hint--bottom:before {
  left: calc(50% + 18px);
}
.map-marker.map-marker--hint-bottom-delta-19 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-19.hint--bottom:before {
  left: calc(50% + 19px);
}
.map-marker.map-marker--hint-bottom-delta-20 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-20.hint--bottom:before {
  left: calc(50% + 20px);
}
.map-marker.map-marker--hint-bottom-delta-21 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-21.hint--bottom:before {
  left: calc(50% + 21px);
}
.map-marker.map-marker--hint-bottom-delta-22 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-22.hint--bottom:before {
  left: calc(50% + 22px);
}
.map-marker.map-marker--hint-bottom-delta-23 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-23.hint--bottom:before {
  left: calc(50% + 23px);
}
.map-marker.map-marker--hint-bottom-delta-24 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-24.hint--bottom:before {
  left: calc(50% + 24px);
}
.map-marker.map-marker--hint-bottom-delta-25 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-25.hint--bottom:before {
  left: calc(50% + 25px);
}
.map-marker.map-marker--hint-bottom-delta-26 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-26.hint--bottom:before {
  left: calc(50% + 26px);
}
.map-marker.map-marker--hint-bottom-delta-27 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-27.hint--bottom:before {
  left: calc(50% + 27px);
}
.map-marker.map-marker--hint-bottom-delta-28 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-28.hint--bottom:before {
  left: calc(50% + 28px);
}
.map-marker.map-marker--hint-bottom-delta-29 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-29.hint--bottom:before {
  left: calc(50% + 29px);
}
.map-marker.map-marker--hint-bottom-delta-30 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-30.hint--bottom:before {
  left: calc(50% + 30px);
}
.map-marker.map-marker--hint-bottom-delta-31 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-31.hint--bottom:before {
  left: calc(50% + 31px);
}
.map-marker.map-marker--hint-bottom-delta-32 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-32.hint--bottom:before {
  left: calc(50% + 32px);
}
.map-marker.map-marker--hint-bottom-delta-33 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-33.hint--bottom:before {
  left: calc(50% + 33px);
}
.map-marker.map-marker--hint-bottom-delta-34 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-34.hint--bottom:before {
  left: calc(50% + 34px);
}
.map-marker.map-marker--hint-bottom-delta-35 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-35.hint--bottom:before {
  left: calc(50% + 35px);
}
.map-marker.map-marker--hint-bottom-delta-36 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-36.hint--bottom:before {
  left: calc(50% + 36px);
}
.map-marker.map-marker--hint-bottom-delta-37 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-37.hint--bottom:before {
  left: calc(50% + 37px);
}
.map-marker.map-marker--hint-bottom-delta-38 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-38.hint--bottom:before {
  left: calc(50% + 38px);
}
.map-marker.map-marker--hint-bottom-delta-39 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-39.hint--bottom:before {
  left: calc(50% + 39px);
}
.map-marker.map-marker--hint-bottom-delta-40 .hint--bottom:after,
.map-marker.map-marker--hint-bottom-delta-40.hint--bottom:before {
  left: calc(50% + 40px);
}
.map-marker.hint--balloon .hint__content {
  z-index: 9999;
  transition: background-color 1s ease;
}
.map-marker.hint--balloon:before {
  z-index: 9999;
  transition: border-color 1s ease;
  border-bottom: none;
}
.map-marker.hint--balloon:after {
  border-top: none;
}
.map-marker .map-marker-hint {
  cursor: default;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
  background-color: black;
}
.map-marker .map-marker-hint.noevents {
  background-color: black;
}
.map-marker .map-marker-hint__close-button {
  position: absolute !important;
  padding: 0 !important;
  width: 30px !important;
  height: 30px !important;
  right: 0;
  top: 0;
  color: red;
  visibility: hidden;
  cursor: pointer;
}
.map-marker .map-marker-hint__close-button--visible {
  visibility: visible;
}
.map-marker .map-marker-hint__title {
  margin-bottom: 5px;
}
.map-marker .map-marker-hint__address {
  font-weight: 400;
  font-size: 11px;
  color: #fff;
  margin-bottom: 5px;
}
.map-marker .map-marker-hint__ap-link {
  color: #ddd;
  font-size: 10px;
  font-style: italic;
}
.map-marker .map-marker-hint__ap-link:hover {
  text-decoration: none;
}
.map-marker .map-marker-hint__ap-link--hidden {
  visibility: hidden;
}
.map-marker .map-marker-hint__content {
  height: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.485, 1.65, 0.545, 0.835);
}

.draw {
  background: none;
  border: 0;
  box-sizing: border-box;
  margin: 1em;
  padding: 1em 2em;
  box-shadow: inset 0 0 0 2px #fff;
  font-size: inherit;
  font-weight: 700;
  position: relative;
  vertical-align: middle;
}
.draw:after,
.draw:before {
  box-sizing: inherit;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
}
.draw {
  transition: color 0.25s;
}
.draw:after,
.draw:before {
  border: 2px solid transparent;
  width: 0;
  height: 0;
}
.draw:before {
  top: 0;
  left: 0;
}
.draw:after {
  bottom: 0;
  right: 0;
}
.draw:hover:after,
.draw:hover:before {
  width: 100%;
  height: 100%;
}
.draw:hover:before {
  transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
}
.draw:hover:after {
  transition: border-color 0s ease-out 0.5s, width 0.25s ease-out 0.5s,
    height 0.25s ease-out 0.75s;
}
.meet:hover {
  color: #3f2f1a;
}
.meet:after {
  top: 0;
  left: 0;
}
.meet:hover:before {
  border-top-color: #3f2f1a;
  border-right-color: #3f2f1a;
}
.meet:hover:after {
  border-bottom-color: #3f2f1a;
  border-left-color: #3f2f1a;
  transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
}
[data-hint] {
  position: relative;
  display: inline-block;
}
[data-hint]:after,
[data-hint]:before {
  position: absolute;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  transition: 0.3s ease;
  transition-delay: 0ms;
}
[data-hint]:hover:after,
[data-hint]:hover:before {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.1s;
}
[data-hint]:before {
  content: "";
  position: absolute;
  background: transparent;
  border: 6px solid transparent;
  z-index: 1000001;
}
[data-hint]:after {
  content: attr(data-hint);
  background: #383838;
  color: #fff;
  padding: 8px 10px;
  font-size: 12px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 12px;
  white-space: nowrap;
}
[data-hint=""]:after,
[data-hint=""]:before {
  display: none !important;
}
.hint--top-left:before,
.hint--top-right:before,
.hint--top:before {
  border-top-color: #383838;
}
.hint--bottom-left:before,
.hint--bottom-right:before,
.hint--bottom:before {
  border-bottom-color: #383838;
}
.hint--left:before {
  border-left-color: #383838;
}
.hint--right:before {
  border-right-color: #383838;
}
.hint--top:before {
  margin-bottom: -12px;
}
.hint--top:after,
.hint--top:before {
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.hint--top:focus:after,
.hint--top:focus:before,
.hint--top:hover:after,
.hint--top:hover:before {
  -webkit-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}
.hint--bottom:before {
  margin-top: -12px;
}
.hint--bottom:after,
.hint--bottom:before {
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.hint--bottom:focus:after,
.hint--bottom:focus:before,
.hint--bottom:hover:after,
.hint--bottom:hover:before {
  -webkit-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px);
}
.hint--right:after {
  margin-bottom: -14px;
}
.hint--right:after,
.hint--right:before {
  left: 100%;
  bottom: 50%;
}
.hint--right:focus:after,
.hint--right:focus:before,
.hint--right:hover:after,
.hint--right:hover:before {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}
.hint--left:after {
  margin-bottom: -14px;
}
.hint--left:after,
.hint--left:before {
  right: 100%;
  bottom: 50%;
}
.hint--left:focus:after,
.hint--left:focus:before,
.hint--left:hover:after,
.hint--left:hover:before {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}
.hint--top-left:before {
  margin-bottom: -12px;
}
.hint--top-left:after,
.hint--top-left:before {
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.hint--top-left:after {
  margin-left: 6px;
}
.hint--top-left:focus:after,
.hint--top-left:focus:before,
.hint--top-left:hover:after,
.hint--top-left:hover:before {
  -webkit-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px);
}
.hint--top-right:before {
  margin-bottom: -12px;
}
.hint--top-right:after,
.hint--top-right:before {
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.hint--top-right:after {
  margin-left: -6px;
}
.hint--top-right:focus:after,
.hint--top-right:focus:before,
.hint--top-right:hover:after,
.hint--top-right:hover:before {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
.hint--bottom-left:before {
  margin-top: -12px;
}
.hint--bottom-left:after,
.hint--bottom-left:before {
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.hint--bottom-left:after {
  margin-left: 6px;
}
.hint--bottom-left:focus:after,
.hint--bottom-left:focus:before,
.hint--bottom-left:hover:after,
.hint--bottom-left:hover:before {
  -webkit-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px);
}
.hint--bottom-right:before {
  margin-top: -12px;
}
.hint--bottom-right:after,
.hint--bottom-right:before {
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.hint--bottom-right:after {
  margin-left: -6px;
}
.hint--bottom-right:focus:after,
.hint--bottom-right:focus:before,
.hint--bottom-right:hover:after,
.hint--bottom-right:hover:before {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.hint--large:after,
.hint--medium:after,
.hint--small:after {
  white-space: normal;
  line-height: 1.4em;
}
.hint--small:after {
  width: 80px;
}
.hint--medium:after {
  width: 150px;
}
.hint--large:after {
  width: 300px;
}
[data-hint]:after {
  text-shadow: 0 -1px 0 #000;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}
.hint--error:after {
  background-color: #b34e4d;
  text-shadow: 0 -1px 0 #592726;
}
.hint--warning:after {
  background-color: #c09854;
  text-shadow: 0 -1px 0 #6c5328;
}
.hint--info:after {
  background-color: #3986ac;
  text-shadow: 0 -1px 0 #1a3c4d;
}
.hint--success:after {
  background-color: #458746;
  text-shadow: 0 -1px 0 #1a321a;
}
.hint--always.hint--top:after,
.hint--always.hint--top:before {
  -webkit-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}
.hint--always.hint--top-left:after,
.hint--always.hint--top-left:before {
  -webkit-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px);
}
.hint--always.hint--top-right:after,
.hint--always.hint--top-right:before {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
.hint--always.hint--bottom:after,
.hint--always.hint--bottom:before {
  -webkit-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px);
}
.hint--always.hint--bottom-left:after,
.hint--always.hint--bottom-left:before {
  -webkit-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px);
}
.hint--always.hint--bottom-right:after,
.hint--always.hint--bottom-right:before {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.hint--always.hint--left:after,
.hint--always.hint--left:before {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}
.hint--always.hint--right:after,
.hint--always.hint--right:before {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}
.hint--rounded:after {
  border-radius: 4px;
}
.hint--no-animate:after,
.hint--no-animate:before {
  transition-duration: 0ms;
}
.hint--bounce:after,
.hint--bounce:before {
  transition: opacity 0.3s ease, visibility 0.3s ease,
    -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease,
    transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.3s ease, visibility 0.3s ease,
    transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24),
    -webkit-transform 0.3s cubic-bezier(0.71, 1.7, 0.77, 1.24);
}
.hint--html.hint:after {
  display: none;
  opacity: 0;
}
.hint__content {
  background: #383838;
  color: #fff;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 12px;
  pointer-events: none;
}
.hint--hoverable .hint__content {
  pointer-events: auto;
}
.hint--html {
  position: relative;
  display: inline-block;
}
.hint--html:after,
.hint--html:before {
  position: absolute;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  pointer-events: none;
  transition: 0.3s ease;
  transition-delay: 0ms;
}
.hint--html:hover:after,
.hint--html:hover:before {
  visibility: visible;
  opacity: 1;
  transition-delay: 0.1s;
}
.hint--html:before {
  content: "";
  position: absolute;
  background: transparent;
  border: 6px solid transparent;
  z-index: 1000001;
}
.hint--html .hint__content {
  position: absolute;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
  opacity: 0;
  z-index: 1000000;
  transition: 0.3s ease;
  transition-delay: 0ms;
}
.hint--html:focus .hint__content,
.hint--html:hover .hint__content {
  visibility: visible;
  opacity: 1;
}
.hint--html:hover .hint__content {
  transition-delay: 0.1s;
}
.hint--html.hint--notrans .hint__content,
.hint--html.hint--notrans:after,
.hint--html.hint--notrans:before {
  transition: none;
}
.hint--top .hint__content {
  bottom: 100%;
  left: 50%;
}
.hint--top .hint__content,
.hint--top:before {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.hint--top .hint__content:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red;
}
.hint--top:focus .hint__content,
.hint--top:hover .hint__content {
  -webkit-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}
.hint--top-left .hint__content {
  bottom: 100%;
  left: 50%;
}
.hint--top-left .hint__content,
.hint--top-left:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.hint--top-left:before {
  margin-left: 6px;
}
.hint--top-left .hint__content {
  margin-left: 12px;
}
.hint--top-left .hint__content:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red;
}
.hint--top-left:focus .hint__content,
.hint--top-left:hover .hint__content {
  -webkit-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px);
}
.hint--top-right .hint__content {
  bottom: 100%;
  left: 50%;
}
.hint--top-right .hint__content,
.hint--top-right:before {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.hint--top-right:before {
  margin-left: -6px;
}
.hint--top-right .hint__content {
  margin-left: -12px;
}
.hint--top-right .hint__content:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red;
}
.hint--top-right:focus .hint__content,
.hint--top-right:hover .hint__content {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
.hint--bottom .hint__content {
  top: 100%;
  left: 50%;
}
.hint--bottom .hint__content,
.hint--bottom:before {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.hint--bottom .hint__content:after {
  content: " ";
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red;
}
.hint--bottom:focus .hint__content,
.hint--bottom:hover .hint__content {
  -webkit-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px);
}
.hint--bottom-left .hint__content {
  top: 100%;
  left: 50%;
}
.hint--bottom-left .hint__content,
.hint--bottom-left:before {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.hint--bottom-left:before {
  margin-left: 6px;
}
.hint--bottom-left .hint__content {
  margin-left: 12px;
}
.hint--bottom-left .hint__content:after {
  content: " ";
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red;
}
.hint--bottom-left:focus .hint__content,
.hint--bottom-left:hover .hint__content {
  -webkit-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px);
}
.hint--bottom-right .hint__content {
  top: 100%;
  left: 50%;
}
.hint--bottom-right .hint__content,
.hint--bottom-right:before {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.hint--bottom-right:before {
  margin-left: -6px;
}
.hint--bottom-right .hint__content {
  margin-left: -12px;
}
.hint--bottom-right .hint__content:after {
  content: " ";
  position: absolute;
  top: -12px;
  left: 0;
  height: 12px;
  width: 100%;
  opacity: 0;
  background-color: red;
}
.hint--bottom-right:focus .hint__content,
.hint--bottom-right:hover .hint__content {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.hint--right:before {
  margin-left: -12px;
  margin-bottom: -6px;
}
.hint--right .hint__content {
  margin-bottom: -14px;
  left: 100%;
  bottom: 50%;
}
.hint--right .hint__content:after {
  content: " ";
  position: absolute;
  left: -12px;
  top: 0;
  height: 100%;
  width: 12px;
  opacity: 0;
  background-color: red;
}
.hint--right:focus .hint__content,
.hint--right:hover .hint__content {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}
.hint--left:before {
  margin-right: -12px;
  margin-bottom: -6px;
}
.hint--left .hint__content {
  margin-bottom: -14px;
  right: 100%;
  bottom: 50%;
}
.hint--left .hint__content:after {
  content: " ";
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 12px;
  opacity: 0;
  background-color: red;
}
.hint--left:focus .hint__content,
.hint--left:hover .hint__content {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}
.hint--error .hint__content {
  background-color: #b34e4d;
}
.hint--error.hint--top-left:before,
.hint--error.hint--top-right:before,
.hint--error.hint--top:before {
  border-top-color: #b34e4d;
}
.hint--error.hint--bottom-left:before,
.hint--error.hint--bottom-right:before,
.hint--error.hint--bottom:before {
  border-bottom-color: #b34e4d;
}
.hint--error.hint--left:before {
  border-left-color: #b34e4d;
}
.hint--error.hint--right:before {
  border-right-color: #b34e4d;
}
.hint--warning .hint__content {
  background-color: #c09854;
}
.hint--warning.hint--top-left:before,
.hint--warning.hint--top-right:before,
.hint--warning.hint--top:before {
  border-top-color: #c09854;
}
.hint--warning.hint--bottom-left:before,
.hint--warning.hint--bottom-right:before,
.hint--warning.hint--bottom:before {
  border-bottom-color: #c09854;
}
.hint--warning.hint--left:before {
  border-left-color: #c09854;
}
.hint--warning.hint--right:before {
  border-right-color: #c09854;
}
.hint--info .hint__content {
  background-color: #3f2f1a;
}
.hint--info.hint--top-left:before,
.hint--info.hint--top-right:before,
.hint--info.hint--top:before {
  border-top-color: #3f2f1a;
}
.hint--info.hint--bottom-left:before,
.hint--info.hint--bottom-right:before,
.hint--info.hint--bottom:before {
  border-bottom-color: #3f2f1a;
}
.hint--info.hint--left:before {
  border-left-color: #3f2f1a;
}
.hint--info.hint--right:before {
  border-right-color: #3f2f1a;
}
.hint--success .hint__content {
  background-color: #458746;
}
.hint--success.hint--top-left:before,
.hint--success.hint--top-right:before,
.hint--success.hint--top:before {
  border-top-color: #458746;
}
.hint--success.hint--bottom-left:before,
.hint--success.hint--bottom-right:before,
.hint--success.hint--bottom:before {
  border-bottom-color: #458746;
}
.hint--success.hint--left:before {
  border-left-color: #458746;
}
.hint--success.hint--right:before {
  border-right-color: #458746;
}
.hint--html.hint--hidden:hover .hint__content,
.hint--html.hint--hidden:hover:after,
.hint--html.hint--hidden:hover:before {
  opacity: 0;
  visibility: hidden;
}
.hint--always .hint__content,
.hint--always:after,
.hint--always:before {
  opacity: 1;
  visibility: visible;
}
.hint--always.hint--top .hint__content {
  -webkit-transform: translateX(-50%) translateY(-8px);
  transform: translateX(-50%) translateY(-8px);
}
.hint--always.hint--top-left .hint__content {
  -webkit-transform: translateX(-100%) translateY(-8px);
  transform: translateX(-100%) translateY(-8px);
}
.hint--always.hint--top-right .hint__content {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
.hint--always.hint--bottom .hint__content {
  -webkit-transform: translateX(-50%) translateY(8px);
  transform: translateX(-50%) translateY(8px);
}
.hint--always.hint--bottom-left .hint__content {
  -webkit-transform: translateX(-100%) translateY(8px);
  transform: translateX(-100%) translateY(8px);
}
.hint--always.hint--bottom-right .hint__content {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.hint--always.hint--left .hint__content {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}
.hint--always.hint--right .hint__content {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}
